import React,{useEffect, useState} from 'react'
import "./index.css";


function Home() {
    const [custid, setCustid] = useState("Loading...")
    const [activeButton, setActiveButton] = useState(null);
    const [data, setData] = useState(null)
    const [isButtonReady, setIsButtonReady] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalDescription, setModalDescription] = useState("");

    const [buttonData, setButtonData] = useState([
        {
            label: 'Flight Timeline',
            icon: 'flight',
            url : "#",
            notificationPopup: false,
            notificationText: ""
        },
        {
            label: 'Add On Ground Activities / Wifi',
            icon: 'add',
            url: "#",
            notificationPopup: false,
            notificationText: ""
        },
        {
            label: 'Add On Flight / Insurance / Seat Upgrade',
            icon: 'add',
            url: "#",
            notificationPopup: false,
            notificationText: ""
        },
        {
            label: 'Request Trip Confirmation Letter',
            icon: 'doc',
            url: "#",
            notificationPopup: false,
            notificationText: ""
        },
    ])

    useEffect(() => {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)
        const custid = searchParams.get("custid")
        if(custid){
            setCustid(custid)
            fetch(`https://n8n.arbatravel.com/webhook/68a8a1c2-d6a6-4e5b-a4b1-24e64134fa9d?custid=${custid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(data => {
                setIsButtonReady(true);
                setButtonData([{
                    label: 'Flight Timeline',
                    icon: 'flight',
                    url : data[0].flight_timeline,
                    notificationPopup: false,
                    notificationText: ""
                },
                {
                    label: 'Add On Travel Insurance Takaful / Pocket Wifi / Ground Activities',
                    icon: 'add',
                    url: data[0].ground_link,
                    notificationPopup: false,
                    notificationText: ""
                },
                {
                    label: 'Add On Flight / Seat Upgrade',
                    icon: 'add',
                    url: data[0].yezza_link,
                    notificationPopup: true,
                    notificationText: "Please note that for Joined Tours, flight tickets are booked under group reservations. Therefore, seat upgrade requests for group bookings can only be submitted to the airline 21-30 days prior to departure (exact timing varies by airline). If the requested seat is unavailable at that time, any payment made for the upgrade will be refunded. Please be aware that seat upgrades are not guaranteed for Joined Tour bookings."
                },
                {
                    label: 'Request Trip Confirmation Letter',
                    icon: 'doc',
                    url: "https://n8n.arbatravel.com/form/request_trip_confirmation_letter",
                    notificationPopup: false,
                    notificationText: ""
                }])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
    },[])

    

  const handleClick = (index) => {
    if (isButtonReady === false) return;
    setActiveButton(index);
    if (buttonData[index].notificationPopup) {
      setIsModalOpen(true);
      setModalTitle(buttonData[index].label);
      setModalDescription(buttonData[index].notificationText);
        setTimeout(() => {
            setIsModalOpen(false);
            window.open(buttonData[index].url, "_self");
        }, 5000);
        return;
    }
    
    window.open(buttonData[index].url, "_self");
  };

  return (
    <div style={{ textAlign: "center", backgroundColor: "#f0f0f0", height: "100vh" }}>
      <header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "red",
          padding: "20px",
        }}
      >
        <img
          src="https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_2872/https://arbatravel.com/wp-content/uploads/2019/12/ARBA-Travel-Logo-optimized.png"
          alt="ARBA Logo"
          style={{ width: "100px", marginRight: "10px" }}
        />
      </header>
      <div className="container">
      <div className="button-container">
        <p style={{fontSize: 18}}>Customer ID : {custid}</p>
        {buttonData.map((data, index) => (
          <div
            key={index}
            className={`button-item ${activeButton === index ? 'active' : ''}`}
            onClick={() => handleClick(index, data.url)}
          >
            <i className={`icon-${data.icon}`}></i>
            <span>{data.label}</span>
            {!isButtonReady && <div className="spinner"></div>}
          </div>
        ))}
      </div>
    </div>

    {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Disclaimer</h2>
            </div>
            <div className="modal-body">
              <p>{modalDescription}</p>
            </div>
          </div>
        </div>
      )}
      
    </div>
  )
}

export default Home