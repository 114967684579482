import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import CustomerTrip from "./CustomerTrip";
import FlightTimeline from "./FlightTimeline";
import Home from "./Home";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/customer-trip",
    element: <CustomerTrip />,
  },
  {
    path: "/flight-timeline",
    element: <FlightTimeline />,
  },{
    path : "/",
    element: <Home />
  },
  {
    path: "/*",
    element: <div style={{display: "flex", justifyContent: "center", alignItems: "center", height:"100vh"}}><span>Oops! It looks like you're lost.<br/> Head over to <a href="https://customer.arbatravel.com">customer.arbatravel.com</a></span></div>,
  }
]);

function App() {
  return (
    <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  )
}

export default App
